// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/ng-zorro-antd/resizable/style/entry.less";
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #4B57DC;
@text-selection-bg: #6671e5;
@font-family: 'Poppins', sans-serif;
